import React, { useEffect, useState, useRef } from 'react'

import { getRegisterFormData } from 'state/actions'
import { useDispatch } from 'react-redux'

import RegisterForm from 'components/forms/registerForm'

import { X } from 'react-feather'

import { useHistory } from 'react-router'

export default function RegisterProduct({ closeModal, type, data }) {
	const { title, copy, registration, isAddAid, user, isSupport, } = data

	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {
		let _formValues = {}

		dispatch(getRegisterFormData(({ data }) => {
			if (window.qrCodeData) {
				const { type } = window.qrCodeData

				if (type) {
					const product = data.products.find(product => product.product_url === type.toLowerCase())

					if (product) {
						delete window.qrCodeData

						setFormValues({
							...formValuesRef.current,
							product_id: product.id
						})
					}
				}
			}
		}))

		if (registration) {
			_formValues = { ...registration }
		}

		if (isSupport && user) {
			_formValues['userId'] = user.id
		}

		if (window.qrCodeData) {
			const {
				aid,
				did,
				rid,
				serial_number
			} = window.qrCodeData

			if (aid) {
				_formValues.aid = aid
			}

			if (did) {
				_formValues.did = did
			}

			if (rid) {
				_formValues.raw_id = rid
			}

			if (serial_number) {
				_formValues.serial_number = serial_number
			}
		}

		setFormValues(_formValues)
	}, [])

	const [formValues, setFormValues] = useState({})
	const formValuesRef = useRef(formValues)
	formValuesRef.current = formValues

	const submitCallback = (response, handleError) => {
		if (response.type === 'FAILED') {
			if (response.data) {
				const firstError = response.data[Object.keys(response.data)[0]]

				if (firstError && firstError.length) {
					handleError(null, typeof firstError === 'string' ? firstError : firstError[0])
				}
			} else {
				handleError(null, `An unexpected error has occurred. Please contact support.`)
			}
		} else {
			const registeredProduct = response.data.registrations.find(_registration => _registration.serial_number === formValuesRef.current.serial_number)

			closeModal()

			if (!isSupport && !user) {
				history.push(`/my-products/${registeredProduct.id}`)
			}
		}
	}

	const handleChange = (key, value) => {
		const _formValues = { ...formValues }

		_formValues[key] = value

		setFormValues(_formValues)
	}

	return (
		<div className={type} onClick={e => e.stopPropagation()}>
			<X className='modal-close' onClick={closeModal} />
			<div>
				<RegisterForm
					heading={title}
					copy={copy}
					formValues={formValues}
					handleChange={handleChange}
					loggedIn={true}
					_skipforNow={null}
					submitCallback={submitCallback}
					isAddAid={isAddAid}
				/>
			</div>
		</div>
	)
}
