import React, { useEffect } from 'react'

import {
    Route,
    Switch,
	useHistory
} from "react-router-dom"

import { useDispatch } from 'react-redux'

import { getCameraBrands } from 'state/actions'
import { ContextProvider } from 'utils/context'

import Auth from 'components/auth/auth'
import Main from 'components/main'
import ProcessQueryParam from 'components/processQueryParam'
import CookiePopup from 'components/cookiePopup'

import { init } from 'utils/ga'

const routes = [
    {
        path: '/auth',
        Component: Auth
    }, {
        path: '/register',
        Component: ProcessQueryParam
    }, {
        path: '',
        Component: Main
    }
]

const AppInner = () => {
	const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        init(`G-LWFMWVWK3D`, history)

        dispatch(getCameraBrands())
    }, [])

    return (
        <ContextProvider>
            <Switch>
                {routes.map(route => {
                    const {
                        path,
                        Component
                    } = route

                    return (
                        <Route key={path} path={path} render={params => (
                            <Component {...params} />
                        )} />
                    )
                })}
            </Switch>
            <CookiePopup />
        </ContextProvider>
    )
}

export default AppInner