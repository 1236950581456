import React from 'react'

const CardDisplay = ({ user }) => {
	const cardSlug = (() => {
		switch (user.card_brand) {
			case 'Visa':
				return 'visa'
			case 'MasterCard':
				return 'mastercard'
			case 'American Express':
				return 'amex'
		}

		return null
	})()

	if (!cardSlug) {
		return null
	}

	return (
		<div className={'existing-card-display'}>
			{cardSlug && <img src={`/images/cards/${cardSlug}.png`} />}
			<span>
				&bull; &bull; &bull; &bull; &nbsp; &bull; &bull; &bull; &bull;
				&nbsp; &bull; &bull; &bull; &bull; &nbsp; {user.card_last_four}
			</span>
		</div>
	)
}

export default CardDisplay