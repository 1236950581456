import React, { useEffect, useMemo, useState } from 'react'

import FeatureTile from 'components/featureTile'
import NoData from 'components/singleRegistration/noData'

import FadeCollapse from 'components/fadeCollapse'

import { ownsFeature, getPrice } from 'utils'
import { generateUrl, signUrl } from 'utils/cloud-studio-utils'

import {
	ChevronRight
} from 'react-feather'

import { useSelector } from 'react-redux'
import { getUserAccountData } from 'state/selectors'
import moment from 'moment'

const Features = ({ registration, isSupport, canActivate, user }) => {
	const {
		product,
		feature_instances,
		aid
	} = registration


	const userAccountData = useSelector(getUserAccountData)

	const [showFreeFeatures, setShowFreeFeatures] = useState(false)

	const [cloudStudioUrl, setCloudStudioUrl] = useState(null)

	const { features } = product

	const hasCloudStudioPromo = useMemo(() => {
		if (!registration) {
			return null
		}

		const december12 = moment('2023-12-12')

		const cloudStudioProductIds = [
			173, 174, 175, 166,
			177 // dev
		]

		const createdMoment = moment(registration.created_at)

		if (createdMoment.isAfter(december12)) {
			return cloudStudioProductIds.includes(registration?.product_id)
		}

		return null
	}, [registration])



	useEffect(() => {
		if (hasCloudStudioPromo && registration?.product) {
			(async () => {
				try {
					const urlWithSignature = await generateUrl(registration)

					if (urlWithSignature) {
						setCloudStudioUrl(urlWithSignature)
					}
				} catch (e) {

				}
			})()
		}
	}, [hasCloudStudioPromo])

	if (!feature_instances) {
		return null
	}

	const specialFeatures = []
	const paidFeatures = []
	const freeFeatures = []

	const organiseFeatures = features => {
		return (features ? features.filter(feature => {
			if (!aid && feature.price_usd && !product.uses_pearson_activation) {
				return false
			}


			return true
		}) : []).sort((a, b) => {
			return a.order - b.order
		})
	}

	organiseFeatures(features).forEach(feature => {
		if (feature.id === 5) {
			return
		}

		if (feature.is_special_feature) {
			return specialFeatures.push(feature)
		}

		const price = getPrice(feature, registration)

		if (price) {
			return paidFeatures.push(feature)
		}

		freeFeatures.push(feature)
	})

	if (hasCloudStudioPromo) {
		paidFeatures.push({
			name: 'Atomos Cloud Studio Free Trial',
			brief_description: `Get the All-inclusive plan FREE for 6 months!`,
			image: `/images/atomos-cloud-thumb.jpg`,
			id: -1,
			url: cloudStudioUrl
		})
	}

	return (
		<section className='singleRegistration-features'>
			{(specialFeatures.length > 0) &&
				<ul className={!specialFeatures.length ? 'no-features' : ''}>
					{specialFeatures.map((featureItem, index) => {
						const alreadyOwns = ownsFeature(feature_instances, featureItem, product, userAccountData)

						return (
							<li key={index}>
								<FeatureTile
									owns={alreadyOwns}
									feature={featureItem}
									registration={registration}
									isSupport={isSupport}
									canActivate={canActivate}
									showNewTab={featureItem?.url || (!alreadyOwns && featureItem.price_usd)}
									user={user}
								/>
							</li>
						)
					})}
				</ul>
			}
			{(paidFeatures || hasCloudStudioPromo) ?
				<>
					{specialFeatures.length > 0 &&
						<div
							className="singleRegistration-features-paid-title"
						>
							<h3>Paid Features</h3>
						</div>
					}
					<div className={`singleRegistration-features-paid-cont`}>
						<ul className={!paidFeatures.length ? 'no-features' : ''}>
							{paidFeatures.length ?
								paidFeatures.map((featureItem, index) => {
									const alreadyOwns = ownsFeature(feature_instances, featureItem, product, userAccountData)

									return (
										<li key={index}>
											<FeatureTile
												owns={alreadyOwns}
												feature={featureItem}
												registration={registration}
												isSupport={isSupport}
												canActivate={canActivate}
												showNewTab={featureItem?.url || (!alreadyOwns && featureItem.price_usd)}
												user={user}
											/>
										</li>
									)
								})
								:
								<NoData
									title='No features found'
									copy={`There currently aren't any features available for this product.`}
								/>
							}
						</ul>
					</div>
				</>
				:
				null
			}
			{freeFeatures.length ?
				<>
					<div
						className="singleRegistration-features-free-title"
						onClick={() => setShowFreeFeatures(!showFreeFeatures)}
					>
						<h3>
							Codecs
						</h3>
						<div className={`icon ${showFreeFeatures ? 'open' : 'closed'}`}>
							<ChevronRight size={20} />
						</div>
					</div>
					<FadeCollapse open={showFreeFeatures}>
						<div className={`singleRegistration-features-free-cont`}>
							{freeFeatures &&
								<ul className={!freeFeatures.length ? 'no-features' : ''}>
									{freeFeatures.length ?
										freeFeatures.map((featureItem, index) => {
											const alreadyOwns = ownsFeature(feature_instances, featureItem, product, userAccountData)

											return (
												<li key={index}>
													<FeatureTile
														owns={alreadyOwns}
														feature={featureItem}
														registration={registration}
														isSupport={isSupport}
														canActivate={canActivate}
														showNewTab={!alreadyOwns && featureItem.price_usd}
														user={user}
													/>
												</li>
											)
										})
										:
										<NoData
											title='No features found'
											copy={`There currently aren't any features available for this product.`}
										/>
									}
								</ul>

							}
						</div>
					</FadeCollapse>
				</>
				:
				null
			}
		</section>
	)
}

export default Features