import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'

import Button from 'components/button'

const CookiePopUp = () => {
    const [cookiePopupVisible, setCookiePopupVisible] = useState(false)

    useEffect(() => {
        const hasDismissedCookies = Cookies.get('hasDismissedCookiePopup')

        if(!hasDismissedCookies){
            setTimeout(() => {
                setCookiePopupVisible(true)
            },2000)
        }
    }, [])

    const close = () => {
        Cookies.set('hasDismissedCookiePopup', true)

        setCookiePopupVisible(false)
    }

    return (
        <div className={`cookie-popup ${ cookiePopupVisible ? 'visible' : ''}`}>
            <h2>
                Cookies &amp; Privacy
            </h2>
            <div className={'copy'}>
                <p>
                    We use cookies for authentication and core functionality. By continuing to use this site, you agree to their use.
                </p>
            </div>
            <div className="buttons-row">
                <a href={'https://atomos.com/cookie-policy'} target="_blank">
                    Cookie policy
                </a>
                <Button
                    label={'Understood'}
                    onClick={close}
                />
            </div>
        </div>
    )
}

export default CookiePopUp