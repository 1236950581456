import React, { useState, useEffect, useRef } from 'react'
import { ChevronDown } from 'react-feather'

import UserButtonMenu from 'components/userButtonMenu'

import User from 'assets/images/user.jpg'

const UserButton = ({ user }) => {
	const [visible, setVisible] = useState(false)
	const visibleRef = useRef(visible)
	visibleRef.current = visible
	const elementRef = useRef(null)

	const handleClickOutsideRef = useRef(e => {
		if (elementRef.current && !elementRef.current.contains(e.target)) {
			if (visibleRef.current) {
				setTimeout(() => {
					setVisible(false)
				}, 100)
			}
		}
	})

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutsideRef.current)

		return () => {
			document.removeEventListener(
				'mousedown',
				handleClickOutsideRef.current
			)
		}
	}, [])

	if (!user) {
		return null
	}

	const openHeaderMenu = () => {
		setVisible(!visible)
	}

	return (
		<div className={'user-button'}>
			<button
				className={visible ? 'menu-visible' : ''}
				ref={ref => (elementRef.current = ref)}
				onClick={() => {
					openHeaderMenu()
				}}
			>
				{User && <img src={User} alt='user' />}
				<span>
					{user.first_name} {user.last_name}
					<ChevronDown size={14} />
				</span>
			</button>
			<UserButtonMenu visible={visible} />
		</div>
	)
}

export default UserButton