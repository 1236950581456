import { useContext, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { MainContext } from './context'
import moment from 'moment'

import { getCurrentUser } from 'state/selectors'

import {
    activatePlayPro,
} from 'state/actions'

export const isDev = !window.location.href.includes('atomos')
export const isStage = window.location.href.includes('yourcreative.com.au')

export const API_URL = isStage ? `https://api.my.atomos.yourcreative.com.au` : isDev ? `http://atomos-laravel.test` : `https://api.my.atomos.com`

export const WP_URL = `https://admin.atomos.com`

export const freeWithRawIdProducts = [171, 172, 173, 174, 175]

export const useModal = () => {
    const dispatch = useDispatch()

    return payload => {
        dispatch({
            type: 'OPEN_MODAL',
            payload
        })
    }
}

export const ToggleMenu = () => {
    const dispatch = useDispatch()

    return payload => {
        dispatch({
            type: 'TOGGLE_MENU',
            payload
        })
    }
}

export const doRequest = (type, url, data) => {
    return axios[type](`${API_URL}/${url}`, data)
}

export const doWordPressRequest = (data, callback) => {
    const tempToken = axios.defaults.headers.common['Authorization']
    delete axios.defaults.headers.common['Authorization']

    axios({
        method: 'POST',
        url: `${WP_URL}/wp-admin/admin-ajax.php`,
        data,
        headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
    }).then(callback)

    if (tempToken) {
        axios.defaults.headers.common['Authorization'] = tempToken
    }
}

export const doWordPressGraphQLRequest = query => {
    const tempToken = axios.defaults.headers.common['Authorization']
    delete axios.defaults.headers.common['Authorization']

    const promise = axios({
        method: 'POST',
        url: `${WP_URL}/graphql`,
        data: {
            query
        },
        headers: {
            'content-type': 'application/json;charset=UTF-8'
        },
    })

    if (tempToken) {
        axios.defaults.headers.common['Authorization'] = tempToken
    }

    return promise
}

export const setSliderScrollListener = (ref, handleChange) => {
    setTimeout(() => {
        if (ref.current) {
            ref.current.on('scroll', progress => {
                const _progress = Math.max(0, Math.min(1, progress))

                handleChange(_progress * 100, true)
            })
        }
    }, 10)
}

export const handleChangeStart = (isDragging, sliderRef, slidesRef, handlePositionRef, flickityRef, maxScroll) => {
    if (!isDragging) {
        const sliderWidth = sliderRef.current.getBoundingClientRect().width
        const firstSlideWidth = slidesRef.current[0].getBoundingClientRect().width

        const pixelsFromLeft = (sliderWidth * ((handlePositionRef.current / 100) * (maxScroll / 100)))

        let closestIndexData = null

        slidesRef.current.forEach((slide, index) => {
            const thisOffset = firstSlideWidth * index

            const distance = Math.abs(thisOffset - pixelsFromLeft)

            if (!closestIndexData || distance < closestIndexData.distance) {
                closestIndexData = {
                    index,
                    distance,
                    offset: thisOffset
                }
            }
        })

        setTimeout(() => {
            flickityRef.current.x = (pixelsFromLeft * -1)
            flickityRef.current.select(closestIndexData.index)
        }, 1)
    }
}

export const useMainContext = () => {
    const context = useContext(MainContext)

    return context
}

export const emailIsValid = email => {
    //eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase())
}

export const usesAid = productId => {
    const productIds = [145, 153, 154, 155, 159, ...freeWithRawIdProducts]

    return ~productIds.indexOf(productId)
}

export const usesRawId = productId => {
    return ~freeWithRawIdProducts.indexOf(productId)
}

export const ignoreDID = productId => {
    const noDIDProducts = [146, 147, 157, 169, 166, 168]

    return noDIDProducts.includes(productId)
}

export const hasNoActivation = productId => {
    const noDIDProducts = [146, 147, 157, 169]

    return noDIDProducts.includes(productId)
}

export const formatDate = date => {
    if (date && date !== '0000-00-00') {
        return moment(date).format(`MMMM Do YYYY`)
    }

    return '(No purchase date)'
}


const constrain = 300

const transforms = (x, y, el) => {
    const box = el.getBoundingClientRect()
    const calcX = -(y - box.y - (box.height / 2)) / (constrain * 2)
    const calcY = (x - box.x - (box.width / 2)) / constrain

    return `perspective(100px) rotateX(${calcX}deg) rotateY(${calcY}deg)`
};

const transformElement = (el, xyEl) => {
    if (el) {
        el.style.transform = transforms.apply(null, xyEl);
    }
}

export const usePerspectiveHover = () => {
    const ex1Layer = useRef(null)

    const onMouseMove = e => {
        let xy = [e.clientX, e.clientY]
        let position = xy.concat([ex1Layer.current])

        window.requestAnimationFrame(() => {
            transformElement(ex1Layer.current, position)
        })
    }

    const onMouseLeave = e => {
        if (ex1Layer.current) {
            ex1Layer.current.style.transition = '0.3s'
        }

        window.requestAnimationFrame(() => {
            if (ex1Layer.current) {
                ex1Layer.current.style.transform = `perspective(100px) rotateX(0deg) rotateY(0deg)`
            }
        })
    }

    const onMouseEnter = e => {
        setTimeout(() => {
            if (ex1Layer.current) {
                ex1Layer.current.style.transition = `box-shadow 0.2s`
            }
        }, 300)
    }

    return {
        ex1Layer,
        onMouseMove,
        onMouseLeave,
        onMouseEnter
    }
}

export const getTokenDownloadUrl = (registrationId, featureId, recalibrate) => {
    return `${API_URL}/account/downloadToken?registrationId=${registrationId}&featureId=${featureId}${recalibrate ? '&recalibrate=1' : ''}`
}

export const isSupport = user => {
    const level1 = [7, 8, 9]
    return user && user.roles && user.roles.find(role => (level1.includes(role.id)))
}

export const supportUserCan = (user, action) => {
    const level1 = [7, 8, 9]
    const level2 = [8, 9]
    const level3 = [9]

    if (user) {
        if (user.roles) {
            switch (action) {
                case 'SEARCH':
                    return user.roles.find(role => (level1.includes(role.id)))
                case 'EDIT_DETAILS':
                    return user.roles.find(role => (level2.includes(role.id)))
                case 'ACTIVATE_FEATURES':
                    return user.roles.find(role => (level3.includes(role.id)))
            }
        }
    }
}

export const filterSoftDeletes = (array, hasFeature) => {
    return array ? array.filter(item => !item.deleted_at && (hasFeature ? item.feature : true)) : []
}

export const getPrice = (feature, registration, _isSupport, skipPromos) => {
    const {
        price_usd,
        free_product_ids
    } = feature

    if (!price_usd || _isSupport) {
        return 0
    }

    if (free_product_ids && ~free_product_ids.indexOf(registration.product.id)) {
        return 0
    }

    if (!skipPromos) {
        if (feature.id === 8 && registration.feature_instances.find(featureInstance => featureInstance.features_id === 7)) {
            return 99
        }
    }

    const halfPriceH265ProductIds = [178, 179, 180, 181]

    if (feature.id === 4 && ~halfPriceH265ProductIds.indexOf(registration.product.id)) {
        return 49
    }

    if (feature.free_with_raw_id) {
        const isLegacy = !freeWithRawIdProducts.includes(registration.product_id)

        if (isLegacy || registration.raw_id) {
            return 0
        } else {
            return feature.price_usd
        }
    }

    return price_usd
}

export const ownsFeature = (featureInstances, feature, product, user) => {
    if (feature.url && user?.activated_acs_at) {
        return true
    }

    if (feature.preactivated_product_ids && ~feature.preactivated_product_ids.indexOf(product.id)) {
        return true
    }

    if (featureInstances) {
        const _featureInstances = filterSoftDeletes(featureInstances, true)

        return !!_featureInstances.find(featureInstance => {
            if(!featureInstance.feature){
                return 
            }
            
            const idstoCheck = (feature.id === 3 || feature.id === 5) ? [3, 5] : [feature.id]

            return idstoCheck.includes(featureInstance?.feature?.id)
        })
    } else {
        return null
    }
}

export const usePlayProContent = setIsLoading => {
    const { shouldShowPlayProPromo } = useMainContext()

    const dispatch = useDispatch()
    const currentUser = useSelector(getCurrentUser)
    const doModal = useModal()

    const runCouponModal = () => {
        setTimeout(() => {
            doModal({
                type: 'TEXT',
                data: {
                    title: `Your coupon code for Assimilate Play Pro Studio`,
                    copy: `
                        Use the coupon code below to purchase Assimilate Play Pro Studio for US$99 (usually US$299).<br /><br />
                            <strong class="code">PlayProStudio4U</strong><br />
                            Use your coupon code at the webshop below.<br />
                            <ul class="download-links">
                                <li>
                                    <a target="_blank" href="https://store2.assimilateinc.com/product/scratch-play-pro/">
                                        Purchase Assimilate Play Pro Studio
                                    </a>
                                </li>
                            </ul>
                            `,
                    button: {
                        label: `Okay`
                    }
                }
            })
        }, 300)
    }

    const runDispatch = () => {
        dispatch(
            activatePlayPro(currentUser.id, response => {
                response.type === 'SUCCESS' ?
                    doModal({
                        type: 'TEXT',
                        data: {
                            title: `Your activation key for Assimilate Play Pro Studio`,
                            copy: `
                                Use the below key to activate Assimilate Play Pro Studio:<br /><br />
                                <strong class="code">${response.data}</strong><br />
                                Download Assimilate Play Pro directly from the following links:<br />
                                <ul class="download-links">
                                    <li>
                                        <a target="_blank" href="https://downloads.assimilateinc.com/download.aspx?version=latest_win">
                                            <img src="/images/playpro/icon-windows.png" />
                                            Download for Windows
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://downloads.assimilateinc.com/download.aspx?version=latest_mac">
                                            <img src="/images/playpro/icon-mac.png" />
                                            Download for Mac
                                        </a>
                                    </li>
                                </ul>
                                View more information about Assimilate Play Pro Studio <a href="https://www.assimilateinc.com/products/playpro/" target="_blank">here</a>.<br />
                                We've also emailed these instructions to you, so you can safely close this window. 
                            `,
                            button: {
                                label: `Okay`
                            }
                        }
                    })
                    :
                    doModal({
                        type: 'TEXT',
                        data: {
                            title: `Error generating Assimilate Play Pro Studio access key`,
                            copy: `An error occurred generating an access key for Assimilate Play Pro Studio. Please contact <a href="https://atomos.zendesk.com/hc/en-us/requests/new" target="_blank">Atomos support</a>.`,
                            button: {
                                label: `Okay`
                            }
                        }
                    })

                if (setIsLoading) {
                    setIsLoading(false)
                }
            })
        )
    }

    if (shouldShowPlayProPromo === 'PARTIAL') {
        return {
            image: '/images/playpro/widget-partial.jpg',
            bigImage: '/images/playpro/modal-partial.jpg',
            title: `Save US$200 on Assimilate Play Pro Studio`,
            body: `Save $200 on one of the best ProRes RAW transcoders on the market - exclusive to Atomos customers until 31st August, 2023.<br /><br />
            Transcode ANY camera RAW footage, convert ProRes RAW to CinemaDNG, back up and offload camera cards, and many more features!
            `,
            button: {
                title: `Get Coupon code`,
                onClick: () => {
                    runCouponModal()
                }
            }
        }
    }

    return {
        image: '/images/playpro/widget.jpg',
        bigImage: '/images/playpro/modal.jpg',
        title: `Get your FREE Assimilate Play Pro Studio License Key today.`,
        body: `Save $299 on one of the best ProRes RAW transcoders on the market - exclusive to Atomos customers until 31st August, 2023.<br /><br />
        Transcode ANY camera RAW footage, convert ProRes RAW to CinemaDNG, back up and offload camera cards, and many more features - all for FREE!`,
        button: {
            title: `Get License Key`,
            onClick: () => {
                if (setIsLoading) {
                    setIsLoading(true)
                }

                runDispatch()
            }
        }
    }
}