import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useModal, usePerspectiveHover, getPrice } from 'utils'
import { getUserAccountData } from 'state/selectors'
import { activateAcs, deactivateFeature } from 'state/actions'

import Button from 'components/button'

const FeatureTile = ({
	owns,
	feature,
	registration,
	isSupport,
	canActivate,
	user,
	showNewTab,
}) => {
	const doModal = useModal()
	const selectorUser = useSelector(getUserAccountData)
	const dispatch = useDispatch()

	const currentUser = (isSupport && user) ? user : selectorUser

	const { uses_serial_activation } = registration.product

	const {
		ex1Layer,
		onMouseEnter,
		onMouseLeave,
		onMouseMove
	} = usePerspectiveHover()

	const aid = registration.aid
	const did = registration.did

	const {
		name,
		display_name,
		brief_description,
		image,
		id,
		url
	} = feature

	const miniBannerCopy = (() => {
		if (!owns && id === 8 && registration.feature_instances.find(featureInstance => featureInstance.features_id === 7)) {
			return '$99 discount applied'
		}

		return null
	})()

	const price = getPrice(feature, registration, isSupport)
	const priceWithoutPromos = getPrice(feature, registration, isSupport, true)

	const requiresRawId = feature.free_with_raw_id && price // price is 0 if legacy OR RAW ID exists

	const checkPrice = price ? `US$${price}` : 'FREE'

	const buyLabel = (() => {
		if (owns) {
			return isSupport ? `De-activate for ${currentUser.first_name}` : 'Activated!'
		} else {
			return isSupport ? `Activate for ${currentUser.first_name}` : `Activate now (${price !== priceWithoutPromos ? `<del>-US$${priceWithoutPromos}</del>` : ''}${checkPrice}${requiresRawId ? ' or FREE*' : ''})`
		}
	})()

	const isFeatureTileInactive = ((aid && owns && !isSupport) || !canActivate) ? true : false

	const handleClick = () => {
		if (!canActivate) {
			return false
		}
		if (aid || uses_serial_activation) {
			if (owns) {
				if (isSupport) {
					doModal({
						type: 'CONFIRM',
						data: {
							title: `De-activate ${name}?`,
							copy: `<p>Please note this won't de-activate it on the device itself.</p>`,
							callback: () => {
								dispatch(deactivateFeature(feature, registration))
							}
						}
					})
				} else {
					return false
				}
			} else {
				doModal({
					type: 'ACTIVATE_FEATURE',
					data: {
						feature,
						owns,
						isToken: true,
						registration,
						currentUser,
						isSupport
					}
				})
			}
		} else {
			if (owns) {
				doModal({
					type: 'GET_PIN',
					data: {
						registration,
						feature,
						currentUser,
						isNew: false,
					}
				})
			} else if (did) {
				doModal({
					type: 'ACTIVATE_FEATURE',
					data: {
						feature,
						owns,
						isToken: false,
						registration,
						currentUser,
						isSupport
					}
				})
			} else {
				let modalCopy = `You'll need to enter ${registration.product.uses_pearson_activation ? `a DID` : `an AID`} to activate features on your ${registration.product.product_name}.`

				if (!registration.product.uses_pearson_activation) {
					modalCopy = `If your firmware version is below 10.6, enter your DID instead.`
				}

				doModal({
					type: 'TEXT',
					data: {
						title: 'Activation Unsuccessful',
						copy: modalCopy
					}
				})
			}
		}
	}

	const handleActivateAcs = () => {
		dispatch(activateAcs())
	}

	const Element = url ? 'a' : 'div'

	return (
		<div
			className={isFeatureTileInactive ? 'featureTile-container inactive' : 'featureTile-container'}
			onMouseEnter={isFeatureTileInactive ? null : onMouseEnter}
			onMouseMove={isFeatureTileInactive ? null : onMouseMove}
			onMouseLeave={isFeatureTileInactive ? null : onMouseLeave}
		>
			<Element
				className={`featureTile`}
				onClick={url ? handleActivateAcs : handleClick}
				href={url ? url : undefined}
				ref={ref => (ex1Layer.current = ref)}
				target={url ? '_blank' : undefined}
			>
				<div className="featureTile-image-cont">
					{showNewTab ?
						<span className={'new-tab'}>
							New
						</span>
						: null}
					<div className={!image ? 'featureTile-image noImage' : 'featureTile-image'} style={{ backgroundImage: `url(${image})` }} />
				</div>
				<div
					className={`featureTile-details ${owns ? 'product-activated' : ''
						}`}
				>
					<h3 dangerouslySetInnerHTML={{ __html: display_name || name }} />
					<p
						dangerouslySetInnerHTML={{ __html: brief_description }}
					/>
					{miniBannerCopy &&
						<span className='featureTile-mini-banner'>
							{miniBannerCopy}
						</span>
					}
					<Button label={buyLabel} inverted success={!!owns} />
					{ (isFeatureTileInactive && url) ? 
						<button className='featureTile-reactivate-acs'>
							Having trouble? Try activating again.
						</button>
					: null }
				</div>
			</Element>
		</div>
	)
}

export default FeatureTile